import ReactOnRails from 'react-on-rails';
import Index from '../bundles/Portal/Index';
import RequestShowIndex from '../bundles/Portal/RequestShowIndex';
import RequestsListIndex from '../bundles/Portal/RequestsListIndex';

ReactOnRails.register({
  Index
});

ReactOnRails.register({
  RequestShowIndex,
  RequestsListIndex
});
