import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import RequestShow from './components/RequestShow';
import SimpleReactLightbox from 'simple-react-lightbox';

const RequestShowIndex = (props) => {
  //console.log('get request: ' + props)
  let request = props.request;
  //console.log(request);
  
  return (
    <HashRouter>
      <SimpleReactLightbox>
        <RequestShow request={request}></RequestShow>
      </SimpleReactLightbox>
    </HashRouter>
  )
}

export default RequestShowIndex;