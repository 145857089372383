import React, { useState, useEffect } from 'react';
import { Panel } from "primereact/panel";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SRLWrapper } from "simple-react-lightbox";


export const CovidCards = (props) => {

  const [immunizations, setImmunizations] = useState(props);
  useEffect(() => { setImmunizations(props); }, [props]); 

  const covidcards = immunizations.immunizations;

  const imageBodyTemplate = (data) => {
    return ( 
      <>
      {data.photoUrl
        ? <img src={data.photoUrl} alt={data.name} style={ {display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '50%'} }
           className="product-image" height="50" />
        : <img src={`/assets/no-image-available.png`} alt='Image Unavailable' style={ {display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '50%'} }
          className="product-image" height="50" />
      }
      </>
    );
  };

  return (
    <div className="p-col-12">
      <SRLWrapper>
        <Panel style={{fontSize: 26}} header="COVID Cards">
          <div className="p-col-12">
                <div className="p-col-12">
                  <DataTable value={covidcards}
                      className="p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers">
                    
                        <Column field="name" header="Name"></Column>
                        <Column header="Image" body={imageBodyTemplate} style={{ textAlign: "center" }}></Column>
                        <Column field="manufacturer" header="Manufacturer"></Column>
                        <Column field="lotNumber" header="Lot Number"></Column>
                        <Column field="placeAdministered" header="Place Administered"></Column>
                        <Column field="dateAdministered" header="Date Administered"></Column>
                        <Column field="nextDosageDate" header="Next Dosage Date"></Column>
                        
                    </DataTable>
                </div>
            </div>
        </Panel>
      </SRLWrapper>
    </div>
  )
}
