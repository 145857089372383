import React, { useState, useEffect } from "react";
import { Panel } from "primereact/panel";
import { Avatar } from "primereact/avatar";

export const PatientProfile = (props) => {

  const [profile, setProfile] = useState(props);
  useEffect(() => { setProfile(props); }, [props]); 

  const first_name = profile.profile.first_name;
  const last_name = profile.profile.last_name;
  
  return (
    <div className="p-col-12 contacts">
      <Panel style={{fontSize: 26}} header="Patient Profile">
        <div style={{fontSize: 13}} className="p-ml-4">
          <ul>

            <li>
              <button className="p-link">
                <div className="p-ml-4">
                  <Avatar
                    image="/assets/avatar_4.png"
                    size="xlarge"
                    shape="circle"
                  />
                </div>
                {/* <img src="/assets/avatar_1.png" width="35" alt="avatar1" /> */}
                <span className="name">{first_name} {last_name}</span>
                <span className="email">abc@xyz.com</span>
              </button>
            </li>
          
            <li>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-4">
                  <div className="card summary">
                    <span className="title">{first_name} {last_name}</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Date of Birth:</span> May 24, 1941</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Address:</span> 123 Main St., Cary, NC 12345</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Phone:</span> +1.617.555.1212</span>
                    <span className="count visitors">Personal</span>
                  </div>
                </div>
                <div className="p-col-12 p-lg-4">
                  <div className="card summary">
                    <span className="title">Dr. Bob Jones</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Primary Care:</span> Dr. Bob Jones</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Phone:</span> +1.452.232.2323</span>
                    <span className="count purchases">Physician</span>
                  </div>
                </div>
                <div className="p-col-12 p-lg-4">
                  <div className="card summary">
                    <span className="title">Walgreens</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Primary:</span> Walgreens</span>
                    <span className="detail"><span style={{fontWeight: 'bold'}}>Address:</span> 1478 Highland Ave, Needham, MA 02492</span>
                    <span className="count revenue">Pharmacy</span>
                  </div>
                </div>
              </div>
            </li>

          </ul>

        </div>
      </Panel>
    </div>
  );

};
