//import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';


const Index = () => {
  return (
  <HashRouter>
    <ScrollToTop>
      <App></App>
    </ScrollToTop>
  </HashRouter>
  )
}

export default Index;