import React, { useState, useEffect } from 'react';
import { Panel } from "primereact/panel";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { SplitButton } from 'primereact/splitbutton';
import Moment from 'react-moment';
import { ActionCableConsumer } from 'react-actioncable-provider';

export const RequestsList = (props) => {

  const [requests, setRequests] = useState(props);
  useEffect(() => { setRequests(props); }, [props]); 

  const requestListItems = requests.requests;

  const nameTemplate = (data) => {
    const fn = data.first_name
    const ln = data.last_name
    return (
        <>
          <span className="p-column-title">{fn} {ln}</span>
        </>
    )
  }

  const purposeTemplate = (data) => {
    const requestBody = JSON.parse(data.body)
    return (
        <>
          <span className="p-column-title">{requestBody.purpose}</span>
        </>
    )
  }

  const timeTemplate = (data) => {
    const dateToFormat = new Date(data.created_at);        
    return (
        <>
           <span className="p-column-title"><Moment format="YYYY/MM/DD hh:mm A" date={dateToFormat} /></span>
        </>
    )
  }

  const statusTemplate = (data) => {
    return (
        <>
            <span className={`request-badge status-${data.status}`}>{data.status}</span>
        </>
    )
  }


  const detailsTemplate = (data) => {
    return (
        <>
         { (data.status === 'approved')
            ?  
              <Button label="Details" className="p-button-outlined p-button-info p-button-sm" 
                onClick={() => window.location = `/requests/${data.id}` } />
            : 
              <> </>
          }
        </>
    )
  }

  const toolbarItems = [
    {
        label: 'Export',
        icon: 'pi pi-check'
    }
  ];

  const toolbarLeftTemplate = () => {
      return (
          <>
            <Button label="New" icon="pi pi-plus" style={{ marginRight: '.5em' }} 
               onClick={() => window.location = '/requests/new' }/>
          </>
      )
  };

  const toolbarRightTemplate = <SplitButton label="Options" model={toolbarItems} menuStyle={{width: '12rem'}}></SplitButton>;

  const onReceived = (data) => {
    //console.log('onReceived');
    //console.log(data);

    if (data.command === 'refresh') {
      window.location.href = '/requests'
    }
  }

  return (
    <div className="layout-wrapper layout-static layout-main">
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12">

        <Panel style={{fontSize: 18}} header="Requests">
          <Toolbar left={toolbarLeftTemplate}></Toolbar>
          <div className="p-col-12">
                <div className="p-col-12">
                <ActionCableConsumer channel="RequestsChannel" onReceived={onReceived}>
                
                  <DataTable 
                    value={requestListItems} paginator rowHover rows={15}
                    className="p-datatable-sm p-datatable-responsive p-datatable-customers" 
                    // header={header}
                    // rows={15} dataKey="id" rowHover 
                    // selection={selectedCustomers1} 
                    // onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                    >


                    {/* <DataTable value={requestListItems}> */}
                    
                      <Column header="Name" body={nameTemplate}></Column>
                      <Column header="Request Time" body={timeTemplate} ></Column>
                      <Column field="truentity_id" header="Truentity ID"></Column>
                      <Column header="Purpose" body={purposeTemplate}></Column>
                      <Column header="Status" body={statusTemplate}></Column>
                      <Column header="Details" body={detailsTemplate} style={{ textAlign: "center" }}></Column>
                        
                    </DataTable>
                </ActionCableConsumer>
                </div>
            </div>
        </Panel>
      </div>
    </div>
  </div>
  )
}
