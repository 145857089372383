import React, { useState, useEffect } from 'react';

import { PatientProfile } from './PatientProfile';
import { Medications } from './Medications';
import { CovidCards } from './CovidCards';
import { Symptoms } from './Symptoms';

import { Query, useQuery } from "react-apollo";
import gql from "graphql-tag";

const RequestShow = (request) => {

  console.log(request);
  var request_obj = request.request; // todo
  var reply = JSON.parse(request_obj.reply);
  const patient_profile = { 
    'first_name': request_obj.first_name,
    'last_name': request_obj.last_name
  }
  
  //console.log(reply)

  return (
    <div className="layout-wrapper layout-static layout-main">
      <div className="p-grid p-fluid dashboard">
        <PatientProfile profile={patient_profile} ></PatientProfile>
        {reply.medications && reply.medications.length > 0 &&
          <Medications medications={reply.medications}></Medications>
        }
        {reply.immunizations && reply.immunizations.length > 0 &&
          <CovidCards immunizations={reply.immunizations}></CovidCards>
        }
        {reply.symptoms && reply.symptoms.length > 0 &&
          <Symptoms symptoms={reply.symptoms}></Symptoms>
        }
      </div>
    </div>
);
}

export default RequestShow;