import React from 'react';
import { HashRouter } from 'react-router-dom';
import { RequestsList } from './components/RequestsList';

import ActionCable from 'actioncable';

//const cable = ActionCable.createConsumer('ws://192.168.1.5:3000/cable');
//const cable = ActionCable.createConsumer('wss://portal.truentity.net/cable');
//const cable = ActionCable.createConsumer('wss://dev.truentity.net/cable');

import { ActionCableProvider } from 'react-actioncable-provider';

const RequestsListIndex = (props) => {

  //console.log(props)
  let requests = props.requests;
  //console.log(requests)
  //var requests = JSON.parse(requests_json);

  return (
      <HashRouter>
        <ActionCableProvider cable={cable}>
          <RequestsList requests={requests}></RequestsList>
        </ActionCableProvider>
      </HashRouter>
  )
}

export default RequestsListIndex;