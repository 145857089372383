import React, { useState, useEffect } from 'react';
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Avatar } from "primereact/avatar";
import { Toolbar } from 'primereact/toolbar';
import { Menubar } from 'primereact/menubar';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { SRLWrapper } from "simple-react-lightbox";


export const Medications = (props) => {

  const [medications, setMedications] = useState(props);
  useEffect(() => { setMedications(props); }, [props]); 

  const [selectedMeds, setSelectedMeds] = useState(null);

  const meds = medications.medications;
  
  const nameBodyTemplate = (data) => {
    return (
        <>
            <span className="p-column-title">{data.name}</span>
        </>
    );
  };

  const dosageModBodyTemplate = (data) => {
    const d = data.dosage.originalValue ? data.dosage.originalValue : data.dosage.value;
    const u = data.dosage.originalUnit ? data.dosage.originalUnit : data.dosage.unit;
    return (
        <>
            <span className="p-column-title">{d} {u}</span>
        </>
    );
  };
  const strengthBodyTemplate = (data) => {
    const d = data.strength.originalValue ? data.strength.originalValue : data.strength.value;
    const u = data.strength.originalUnit ? data.strength.originalUnit : data.strength.unit;
    return (
        <>
            <span className="p-column-title">{d} {u}</span>
        </>
    );
  };

  const frequencyBodyTemplate = (data) => {
    const d = data.period.originalValue ? data.period.originalValue : data.period.value;
    const u = data.period.originalUnit ? data.period.originalUnit : data.period.unit;
    return (
        <>
            <span className="p-column-title">{d} {u}</span>
        </>
    );
  };

  const dosageBodyTemplate = (data) => {
    const d = data.dosage.value;
    const u = data.dosage.unit;
    return (
        <>
          {data.dosage.isModified
            ? <div style={{ backgroundColor: '#F0E68C', color: '#00448f' }}>
                <span className="p-column-title">
                  {d} {u}
                </span>
              </div>
            : <span className="p-column-title">{d} {u}</span>
          }
        </>
    );
  };

  const strengthModBodyTemplate = (data) => {
    const d = data.strength.value;
    const u = data.strength.unit;
    return (
        <>
          {data.strength.isModified
            ? <div style={{ backgroundColor: '#F0E68C', color: '#00448f' }}>
                <span className="p-column-title">
                  {d} {u}
                </span>
              </div>
            : <span className="p-column-title">{d} {u}</span>
          }
        </>
    );
  };

  const frequencyModBodyTemplate = (data) => {
    const d = data.period.value;
    const u = data.period.unit;
    return (
        <>
          {data.period.isModified
            ? <div style={{ backgroundColor: '#F0E68C', color: '#00448f' }}>
                <span className="p-column-title">
                  {d} {u}
                </span>
              </div>
            : <span className="p-column-title">{d} {u}</span>
          }
        </>
    );
  };
  

  const imageBodyTemplate = (data) => {
    return ( 
      <>
      {data.s3Url
        ? <img src={data.s3Url} alt={data.name} style={ {display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '50%'} }
           className="product-image" height="50" />
        : <img src={`/assets/no-image-available.png`} alt='Image Unavailable' style={ {display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '50%'} }
          className="product-image" height="50" />
      }
      </>
    );
  };

  const toolbarItems = [
    {
        label: 'EHR',
        icon: 'pi pi-external-link'
    },
    {
      label: 'Email',
      icon: 'pi pi-envelope'
    }
  ];

  const toolbarRightTemplate = <SplitButton label="Export" model={toolbarItems} menuStyle={{width: '12rem'}}></SplitButton>;
  
  const actionTemplate = () => <Button type="button" icon="pi pi-cog" className="p-button-secondary"></Button>;

  // const medsTableHeader = (
  //   // <div className="table-header-container">
  //   //     <Button icon="pi pi-plus" label="Expand All" className="p-mr-2" />
  //   //     <Button icon="pi pi-minus" label="Collapse All" />
  //   // </div>
  //   // <div className="table-header">
  //   //   List of Customers
  //   // </div>
  // );

  let headerGroup = <ColumnGroup>
      <Row>
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }} colSpan={1}></Column>
          <Column header="Medication" colSpan={1} />
          <Column header="Image" colSpan={1} style={{ textAlign: "center" }} />
          <Column header="Prescribed" colSpan={3} style={{ textAlign: "center" }} />
          <Column header="Patient Modified" colSpan={3} style={{ textAlign: "center" }} />
          <Column header="Prescriber" colSpan={1} />
          <Column header="Comments" colSpan={1} />
      </Row>
      <Row>
          <Column header="" colSpan={3} />
          <Column header="Dosage" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="Strength" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="Frequency" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="Dosage" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="Strength" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="Frequency" style={{ backgroundColor: "#E0E0E0" }} />
          <Column header="" colSpan={1} />
          <Column header="" colSpan={1} />
      </Row>
    </ColumnGroup>;

  return (
    <div className="p-col-12">
      <SRLWrapper>
        <Panel style={{fontSize: 26}} header="Medications">
          <Toolbar label="dd" left={toolbarRightTemplate}></Toolbar>
          {/* <Menubar model={menuitems}></Menubar> */}
          <div className="p-col-12">
                <div className="p-col-12">
                    <DataTable value={meds} headerColumnGroup={headerGroup}
                        selection={selectedMeds} onSelectionChange={(e) => setSelectedMeds(e.value)}
                        className="p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers">
                    
                        <Column selectionMode="multiple" headerStyle={{ width: '1em' }}></Column>
                        <Column field="name" header="Medication" body={nameBodyTemplate}></Column>
                        <Column header="Image" body={imageBodyTemplate}></Column>
                        <Column field="dosage" header="Dosage" body={dosageBodyTemplate}></Column>
                        <Column field="strength" header="Strength" body={strengthBodyTemplate}></Column>
                        <Column field="frequency" header="Frequency" body={frequencyBodyTemplate}></Column>
                        <Column field="dosage" header="Dosage" body={dosageModBodyTemplate}></Column>
                        <Column field="strength" header="Strength" body={strengthModBodyTemplate}></Column>
                        <Column field="frequency" header="Frequency" body={frequencyModBodyTemplate}></Column>
                        <Column field="prescriber" header="Prescriber"></Column>
                        <Column field="instructions" header="Comments"></Column>
                        
                    </DataTable>
                </div>
            </div>
        </Panel>
      </SRLWrapper>
    </div>
  )
}





// const DataTableBasicDemo = () => {
//     return (
//         <div>
//             <div className="card">
//                 <DataTable value={products}>
//                     <Column field="code" header="Code"></Column>
//                     <Column field="name" header="Name"></Column>
//                     <Column field="category" header="Category"></Column>
//                     <Column field="quantity" header="Quantity"></Column>
//                 </DataTable>
//             </div>
//         </div>
//     );
// }
    

{/* <DataTable value={products} className="p-datatable-customers" rows={5} style={{ marginBottom: '20px' }} paginator>
<Column header="Logo" body={(data) => <img src={`assets/demo/images/product/${data.image}`} alt={data.image} width="50" />}></Column>
<Column field="name" header="Name" sortable></Column>
<Column field="category" header="Category" sortable></Column>
<Column field="price" header="Price" sortable body={(data) => formatCurrency(data.price)}></Column>
<Column header="View" body={() => (
    <>
        <Button icon="pi pi-search" type="button" className="p-button-success p-mr-2 p-mb-1"></Button>
        <Button icon="pi pi-times" type="button" className="p-button-danger p-mb-1"></Button>
    </>
)}></Column>
</DataTable> */}