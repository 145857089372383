import React, { useState, useEffect } from 'react';
import { Panel } from "primereact/panel";
import { Chart } from 'primereact/chart';

export const Symptoms = (props) => {

  const [symptoms, setSymptoms] = useState(props);
  useEffect(() => { setSymptoms(props); }, [props]); 

  const symptomsArray = symptoms.symptoms;
  const symptomTimesInit = symptomsArray.map(symptom => {
      return Date.parse(symptom.datetimeRecorded);
  })
  const symptomTimes = symptomTimesInit.filter(function (value) {
    return !Number.isNaN(value);
  });
  //console.log(symptomTimes)
  const symptomSummaries = symptomsArray.map(symptom => {
    if (symptom.summary === 'Good') return 1;
    else if (symptom.summary === 'Fair') return 0.5;
    else return 0;
  })
  //console.log(symptomSummaries)

  const basicData = {
    labels: symptomTimes,
    datasets: [
        {
            label: 'Symptoms',
            data: symptomSummaries,
            borderColor: '#42A5F5'
        }
    ]
  };

  const basicOptions = {
        // legend: {
        //     labels: {
        //         fontColor: '#495057'
        //     }
        // },
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                      'millisecond': 'MMM DD h:mm a',
                      'second': 'MMM DD h:mm a',
                      'minute': 'MMM DD h:mm a',
                      'hour': 'MMM DD h:mm a',
                      'day': 'MMM DD h:mm a',
                      'week': 'MMM DD h:mm a',
                      'month': 'MMM DD h:mm a',
                      'quarter': 'MMM DD h:mm a',
                      'year': 'MMM DD h:mm a',
                    },
                    tooltipFormat: 'MMM D YYYY h:mm a'
                },
                ticks: {
                    fontColor: '#495057',
                    fontFamily: "Tahoma"
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#495057',
                    fontFamily: "Tahoma",
                    min: 0,
                    max: 1.25,
                    stepSize: 0.5,
                    callback: function(label, index, labels) {
                        switch (label) {
                            case 0:
                                return 'Poor';
                            case 0.5:
                                return 'Fair';
                            case 1:
                                return 'Good';
                        }
                    }
                }
            }]
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        }
    }

  return (
    <div className="p-col-12">
        <Panel style={{fontSize: 26}} header="Symptoms">
        <div className="p-col-12">

          <div className="card">
          <div className="p-col-12">
                <div className="p-col-12">
                  {/* <h5 className="centerText">Symptoms</h5> */}
                  <Chart type="line" data={basicData} options={basicOptions} />
                </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  )
}